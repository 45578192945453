body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden; /* Prevents scrollbars from appearing during movement */
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #000000;
}

.spinner {
  width: 200px;
  height: 200px;
  position: absolute; /* Allows the image to move around the screen */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Centers the image */
}

/* Normal Spin */
@keyframes normal-spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg); /* Keeps centered during normal spin */
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

/* Combined Animations with Movement */

/* Wobble Spin with Movement */
@keyframes wobble-move {
  0% {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(0deg) scale(1);
  }
  25% {
    top: 20vh;
    left: 60vw;
    transform: rotate(90deg) scale(1.1);
  }
  50% {
    top: 50vh;
    left: 30vw;
    transform: rotate(180deg) scale(0.9);
  }
  75% {
    top: 70vh;
    left: 80vw;
    transform: rotate(270deg) scale(1.1);
  }
  100% {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(360deg) scale(1);
  }
}

/* Bounce Spin with Movement */
@keyframes bounce-move {
  0% {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(0deg);
  }
  25% {
    top: 20vh;
    left: 60vw;
    transform: rotate(90deg) translateY(-20px);
  }
  50% {
    top: 50vh;
    left: 30vw;
    transform: rotate(180deg) translateY(0);
  }
  75% {
    top: 70vh;
    left: 80vw;
    transform: rotate(270deg) translateY(20px);
  }
  100% {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

/* Zigzag Spin with Movement */
@keyframes zigzag-move {
  0% {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(0deg);
  }
  25% {
    top: 20vh;
    left: 60vw;
    transform: rotate(90deg) translateX(10px);
  }
  50% {
    top: 50vh;
    left: 30vw;
    transform: rotate(180deg) translateX(-10px);
  }
  75% {
    top: 70vh;
    left: 80vw;
    transform: rotate(270deg) translateX(10px);
  }
  100% {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

/* Crazy Spin with Movement */
@keyframes crazy-move {
  0% {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(0deg) scale(1);
  }
  20% {
    top: 20vh;
    left: 60vw;
    transform: rotate(60deg) scale(1.1);
  }
  40% {
    top: 50vh;
    left: 30vw;
    transform: rotate(120deg) scale(0.9);
  }
  60% {
    top: 70vh;
    left: 80vw;
    transform: rotate(180deg) scale(1.2);
  }
  80% {
    top: 50vh;
    left: 20vw;
    transform: rotate(240deg) scale(0.8);
  }
  100% {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(360deg) scale(1);
  }
}


/* Responsive Image Scaling */
@media (max-width: 768px) {
  .spinner {
    width: 150px;
    height: 150px;
  }

  /* Adjusted keyframes for tablets */
  @keyframes wobble-move {
    0% {
      top: 45%;
      left: 45%;
      transform: translate(-50%, -50%) rotate(0deg) scale(1);
    }
    25% {
      top: 30vh;
      left: 50vw;
      transform: rotate(90deg) scale(1.05);
    }
    50% {
      top: 40vh;
      left: 40vw;
      transform: rotate(180deg) scale(0.95);
    }
    75% {
      top: 50vh;
      left: 60vw;
      transform: rotate(270deg) scale(1.05);
    }
    100% {
      top: 45%;
      left: 45%;
      transform: translate(-50%, -50%) rotate(360deg) scale(1);
    }
  }

  @keyframes bounce-move {
    0% {
      top: 45%;
      left: 45%;
      transform: translate(-50%, -50%) rotate(0deg);
    }
    25% {
      top: 35vh;
      left: 55vw;
      transform: rotate(90deg) translateY(-10px);
    }
    50% {
      top: 40vh;
      left: 40vw;
      transform: rotate(180deg) translateY(0);
    }
    75% {
      top: 50vh;
      left: 60vw;
      transform: rotate(270deg) translateY(10px);
    }
    100% {
      top: 45%;
      left: 45%;
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }

  @keyframes zigzag-move {
    0% {
      top: 45%;
      left: 45%;
      transform: translate(-50%, -50%) rotate(0deg);
    }
    25% {
      top: 35vh;
      left: 55vw;
      transform: rotate(90deg) translateX(5px);
    }
    50% {
      top: 40vh;
      left: 40vw;
      transform: rotate(180deg) translateX(-5px);
    }
    75% {
      top: 50vh;
      left: 60vw;
      transform: rotate(270deg) translateX(5px);
    }
    100% {
      top: 45%;
      left: 45%;
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }

  @keyframes crazy-move {
    0% {
      top: 45%;
      left: 45%;
      transform: translate(-50%, -50%) rotate(0deg) scale(1);
    }
    20% {
      top: 30vh;
      left: 55vw;
      transform: rotate(60deg) scale(1.05);
    }
    40% {
      top: 45vh;
      left: 40vw;
      transform: rotate(120deg) scale(0.95);
    }
    60% {
      top: 50vh;
      left: 60vw;
      transform: rotate(180deg) scale(1.05);
    }
    80% {
      top: 40vh;
      left: 45vw;
      transform: rotate(240deg) scale(0.95);
    }
    100% {
      top: 45%;
      left: 45%;
      transform: translate(-50%, -50%) rotate(360deg) scale(1);
    }
  }
}

@media (max-width: 480px) {
  .spinner {
    width: 100px;
    height: 100px;
  }

  /* Adjusted keyframes for mobile devices */
  @keyframes wobble-move {
    0% {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(0deg) scale(1);
    }
    25% {
      top: 35vh;
      left: 45vw;
      transform: rotate(90deg) scale(1);
    }
    50% {
      top: 50vh;
      left: 40vw;
      transform: rotate(180deg) scale(0.9);
    }
    75% {
      top: 55vh;
      left: 50vw;
      transform: rotate(270deg) scale(1);
    }
    100% {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(360deg) scale(1);
    }
  }

  @keyframes bounce-move {
    0% {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(0deg);
    }
    25% {
      top: 40vh;
      left: 55vw;
      transform: rotate(90deg) translateY(-5px);
    }
    50% {
      top: 50vh;
      left: 40vw;
      transform: rotate(180deg) translateY(0);
    }
    75% {
      top: 55vh;
      left: 50vw;
      transform: rotate(270deg) translateY(5px);
    }
    100% {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }

  @keyframes zigzag-move {
    0% {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(0deg);
    }
    25% {
      top: 40vh;
      left: 55vw;
      transform: rotate(90deg) translateX(3px);
    }
    50% {
      top: 50vh;
      left: 40vw;
      transform: rotate(180deg) translateX(-3px);
    }
    75% {
      top: 55vh;
      left: 50vw;
      transform: rotate(270deg) translateX(3px);
    }
    100% {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }

  @keyframes crazy-move {
    0% {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(0deg) scale(1);
    }
    20% {
      top: 40vh;
      left: 55vw;
      transform: rotate(60deg) scale(1);
    }
    40% {
      top: 50vh;
      left: 40vw;
      transform: rotate(120deg) scale(0.9);
    }
    60% {
      top: 55vh;
      left: 50vw;
      transform: rotate(180deg) scale(1);
    }
    80% {
      top: 50vh;
      left: 45vw;
      transform: rotate(240deg) scale(0.9);
    }
    100% {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(360deg) scale(1);
    }
  }
}
